<template>
	<div class="main-wrapper">
		<CRow>
			<CCol
				col="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>
					General info
				</h4>
			</CCol>
			<CCol col="12">
				<CInput
					v-model="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Name*"
				/>
			</CCol>
			<CCol col="6">
				<CInput
					v-model="$v.formData.titleTh.$model"
					:is-valid="!$v.formData.titleTh.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Accordion Key name (TH)*"
				/>
			</CCol>
			<CCol col="6">
				<CInput
					v-model="$v.formData.titleEn.$model"
					:is-valid="!$v.formData.titleEn.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Accordion Key name (EN) *"
				/>
			</CCol>
		</CRow>

		<!-- Status -->
		<CRow class="mb-5">
			<CCol xl="12" class="d-flex align-items-center">
				<label class="toggle-label" for="display-status">Status</label>
				<CSwitch
					id="display-status"
					:checked="formData.isActive"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
					@update:checked="handleUpdateStatus"
				/>
				<span>{{ statusText }}</span>
			</CCol>
		</CRow>

		<hr class="my-4">

		<!-- Date -->
		<ActiveSchedule
			ref="date"
			:start-date="formData.startDate"
			:start-time="formData.startTime"
			:end-date="formData.endDate"
			:end-time="formData.endTime"
			@update:startDate="formData.startDate = $event"
			@update:startTime="formData.startTime = $event"
			@update:endDate="formData.endDate = $event"
			@update:endTime="formData.endTime = $event"
		/>

		<hr class="my-5">
		<CRow class="mb-5">
			<CCol
				col="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>
					Detail
				</h4>
			</CCol>
			<CCol col="12">
				<label>Description (TH)*</label>
				<BaseRichTextEditor
					v-model="$v.formData.contentTh.$model"
					:is-valid="!$v.formData.contentTh.$error"
					:invalid-feedback="descriptionThError"
				/>
			</CCol>
			<CCol col="12" class="mt-4">
				<label>Description (EN)*</label>
				<BaseRichTextEditor
					v-model="$v.formData.contentEn.$model"
					:is-valid="!$v.formData.contentEn.$error"
					:invalid-feedback="descriptionEnError"
				/>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:disabled-cancel="isSubmitting"
			:disabled-remove="isSubmitting"
			:is-edit="isEdit"
			:remove-text="canRemove"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
			@onRemove="handleRemove"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteAccordionKey.bind(null, $route.params.id)"
			title="Remove this accordion"
			description="Are you sure you want to delete this accordion?"
			@onSuccess="$router.push({ name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_KEY })"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import ActiveSchedule from '@/components/ActiveSchedule.vue';

import { scrollToTop, convertDateTimeToUTC } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';
import { STATUS as mappingStatus } from '../mapping/banner';

export default {
	name: 'AccordionKeysForm',
	components: {
		ActiveSchedule,
	},

	validations: {
		formData: {
			name: {
				required,
			},
			titleTh: {
				required,
			},
			titleEn: {
				required,
			},
			contentTh: {
				required,
			},
			contentEn: {
				required,
			},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formData: {
				name: null,
				titleTh: null,
				titleEn: null,
				contentTh: null,
				contentEn: null,
				isActive: false,
				startDate: null,
				startTime: '00:00',
				endDate: null,
				endTime: '23:00',
			},
			isSubmitting: false,
			disabledResend: false,
			ROUTE_NAME,
		};
	},

	computed: {
		...mapState('accordionKeys', {
			accordionKeysEdit: 'edit',
		}),

		canRemove() {
			return this.isEdit ? 'Remove Accordion Key' : '';
		},
		descriptionEnError() {
			if (!this.$v.formData.contentEn.required) {
				return this.$t('global.error.required');
			}

			return 'null';
		},
		descriptionThError() {
			if (!this.$v.formData.contentTh.required) {
				return this.$t('global.error.required');
			}

			return 'null';
		},
		statusText() {
			const text = mappingStatus[this.formData.isActive] || '';
			return this.$t(text);
		},
	},

	async created() {
		if (this.isEdit) {
			await this.getAccordion(this.$route.params.id);
			const {
				name,
				titleTh,
				titleEn,
				contentTh,
				contentEn,
				isActive,
				startDate,
				startTime,
				endDate,
				endTime,
			} = this.accordionKeysEdit.data;
			this.formData = {
				...this.formData,
				name,
				titleTh,
				titleEn,
				contentTh,
				contentEn,
				isActive,
				startDate,
				startTime,
				endDate,
				endTime,
			};
		}
	},

	methods: {
		...mapActions({
			getAccordion: 'accordionKeys/getAccordionKey',
			createAccordionKey: 'accordionKeys/createAccordionKey',
			updateAccordionKey: 'accordionKeys/updateAccordionKey',
			deleteAccordionKey: 'accordionKeys/deleteAccordionKey',
		}),

		async handleConfirm() {
			const dateRef = this.$refs.date.$v;
			this.$v.$touch();
			dateRef.$touch();

			if (this.$v.$invalid || dateRef.$invalid) {
				scrollToTop();
				return;
			}

			this.isSubmitting = true;

			let startDateTime = null;
			let endDateTime = null;
			if (this.formData.startDate) {
				startDateTime = convertDateTimeToUTC(this.formData.startDate, this.formData.startTime);
			}
			if (this.formData.endDate) {
				endDateTime = convertDateTimeToUTC(this.formData.endDate, this.formData.endTime);
			}
			const formData = {
				name: this.formData.name,
				titleTh: this.formData.titleTh,
				titleEn: this.formData.titleEn,
				contentTh: this.formData.contentEn,
				contentEn: this.formData.contentEn,
				isActive: this.formData.isActive,
				startAt: startDateTime,
				endAt: endDateTime,
			};
			if (!this.isEdit) {
				await this.createAccordionKey(formData);
				this.isSubmitting = false;
				this.$router.push({ name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_KEY });
				return;
			}
			await this.updateAccordionKey({ id: this.$route.params.id, params: formData });
			this.isSubmitting = false;
		},
		handleRemove() {
			this.$refs['modal-remove'].open();
		},
		handleCancel() {
			this.$router.push({ name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_KEY });
		},
		handleUpdateStatus(value) {
			this.formData.isActive = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.toggle {
	// .toggle-lable
	&-label {
		width: 54px;
		margin: 0 24px 0 0;
	}

	// .toggle-status
	&-status {
		margin-right: 12px;
	}
}
</style>
