<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<AccordionKeysForm v-else is-edit />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccordionKeysForm from '@/components/AccordionKeysForm.vue';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'ProductDetailAccordionEdit',
	components: {
		AccordionKeysForm,
	},
	data() {
		return {};
	},

	computed: {
		...mapState('accordionKeys', {
			edit: 'edit',
		}),
	},

	created() {
		this.setBreadcrumbs([
			{
				title: 'Accordion Key',
				route: { name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_KEY },
			},
		]);
	},

	methods: {
		...mapActions({
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),
	},
};
</script>
